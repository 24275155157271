<template>
  <v-dropdown
    placement="top"
    :triggers="['click']"
  >
    <template #default="{ shown }">
      <common-jitsi-actions-leave-meet-btn :icon="shown ? 'close' : 'call'" />
    </template>
    <template #popper>
      <div class="p-3 flex flex-col gap-2 -mx-2 w-56">
        <ui-button
          text="End meeting for all"
          variant="error"
          @click="meetStore.onEndMeetForAll"
        />
        <ui-button
          text="Leave meeting"
          @click="meetStore.onLeaveRoom"
        />
      </div>
    </template>
  </v-dropdown>
</template>

<script setup lang="ts">
  const meetStore = useMeetStore();
</script>
