<template>
  <div
    ref="wrapEl"
    class="w-full h-full flex items-center justify-center mx-auto relative overflow-hidden"
    :class="{ 'lg:max-w-[80%] rounded-lg bg-card-notify-active': !full }"
  >
    <ui-avatar
      :user-name="activeVideoTrack.user.name"
      :src="activeVideoTrack.user.avatar"
    />
    <template v-if="!isShareVideo && activeVideoTrack.data && activeVideoTrack.data.track">
      <canvas
        v-show="!activeVideoTrack.data.muted"
        ref="canvasEl"
        class="absolute inset-0 w-full h-full blur-[40px]"
        :class="{ '-scale-x-100 scale-y-100': videoEl?.mirror }"
      />
      <common-jitsi-video-track
        v-show="!activeVideoTrack.data.muted"
        ref="videoEl"
        :key="activeVideoTrack.data.track.getId() || 'video-main-track'"
        auto-mirror
        :track="activeVideoTrack.data.track"
        class="inset-0 m-auto"
        @loadedmetadata="drawFrame"
      />
    </template>
    <common-jitsi-share-video-provider
      v-if="isShareVideo"
      :video-url="meetStore.videoShareState.link"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
  import { calculateVideoDimensions } from '~/composables/jitsi/largeVideoHelpers';

  type Props = {
    full?: boolean;
  };
  defineProps<Props>();
  const { user } = useAuthUser();
  const meetStore = useMeetStore();
  const wrapEl = ref<HTMLDivElement>();
  const canvasEl = ref<HTMLCanvasElement>();
  const videoEl = ref<{ trackRef?: HTMLVideoElement; mirror: boolean }>();
  let canvasContext: CanvasRenderingContext2D | null = null;
  let animationFrameId: number | undefined;

  const getLocalTrack = () => {
    const track = meetStore.localTracks.desktop || meetStore.localTracks.video;
    return {
      user: {
        id: user.value?.id,
        name: user.value?.username,
        avatar: user.value?.avatar_url,
        email: user.value?.email
      },
      data: {
        stopped: false,
        muted: !!track?.isMuted(),
        track
      }
    };
  };

  const getFirstAvailableRemoteTrack = () => {
    const remoteTracksArr = Object.values(meetStore.remoteTracks);
    if (!remoteTracksArr.length) return null;

    const fallbackTracks = useFind(remoteTracksArr, item =>
      useSome(item.tracks.video, ({ track }) => !track.isLocal())
    );

    if (!fallbackTracks) return null;

    const videoTrack = useFilter(fallbackTracks.tracks.video, item => !item.muted);
    const selectedTrack = useFind(videoTrack, ({ track }) => track.getVideoType() === 'desktop') || videoTrack[0];

    return {
      user: fallbackTracks.user,
      data: selectedTrack
    };
  };

  const getActiveRemoteTrack = () => {
    const activeTrack = meetStore.activeTrack;
    if (!activeTrack) return null;

    const participant = meetStore.remoteTracks[activeTrack.participantId];
    if (!participant) return null;

    let data = useFind(participant.tracks?.video, item => item.id === activeTrack.trackId);
    if (data?.muted) {
      data = useFind(participant.tracks?.video, item => !item.muted);
    }

    return data ? { data, user: participant.user } : null;
  };

  const activeVideoTrack = computed(() => {
    if (meetStore.isConnectedMeet) {
      const remoteTrack = getActiveRemoteTrack();
      if (remoteTrack) {
        return remoteTrack;
      }
      if (meetStore.localTracks.desktop) {
        return getLocalTrack();
      }
      return getFirstAvailableRemoteTrack() || getLocalTrack();
    }
    return getLocalTrack();
  });

  const isShareVideo = computed(() => meetStore.videoShareState.status !== VideoShareStatus.Stop);

  const setSizeVideo = useThrottleFn(() => {
    if (videoEl.value?.trackRef) {
      const { width, height } = calculateVideoDimensions(wrapEl.value);
      videoEl.value.trackRef.style.width = width + 'px';
      videoEl.value.trackRef.style.height = height + 'px';
    }
  }, 20);

  const drawFrame = () => {
    const video = videoEl.value?.trackRef;
    const canvas = canvasEl.value;
    if (video && canvas && canvasContext && video.readyState === video.HAVE_ENOUGH_DATA) {
      canvas.classList.remove('hidden');
      const videoWidth = video.clientWidth;
      const videoHeight = video.clientHeight;

      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;

      const videoAspectRatio = videoWidth / videoHeight;
      const canvasAspectRatio = canvasWidth / canvasHeight;

      let drawWidth,
        drawHeight,
        offsetX = 0,
        offsetY = 0;

      if (videoAspectRatio > canvasAspectRatio) {
        drawHeight = canvasHeight;
        drawWidth = drawHeight * videoAspectRatio;
        offsetX = (canvasWidth - drawWidth) / 2;
      } else {
        drawWidth = canvasWidth;
        drawHeight = drawWidth / videoAspectRatio;
        offsetY = (canvasHeight - drawHeight) / 2;
      }

      canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);
      canvasContext.drawImage(video, offsetX, offsetY, drawWidth, drawHeight);
    }
    animationFrameId = requestAnimationFrame(drawFrame);
  };

  useEventListener(window, 'resize', setSizeVideo);
  watch(
    canvasEl,
    el => {
      if (el) {
        canvasContext = el.getContext('2d');
        return;
      }
      canvasContext = null;
    },
    {
      immediate: true
    }
  );
  watch(
    videoEl,
    el => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = undefined;
        if (canvasEl.value) {
          canvasEl.value.classList.add('hidden');
        }
      }
      if (el) {
        setSizeVideo();
      }
    },
    {
      immediate: true
    }
  );
</script>
