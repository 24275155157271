<template>
  <common-jitsi-embeded-basic-embed
    ref="rootEl"
    :ready="ready"
    :placeholder="placeholder"
    :ended="ended"
    :is-moderator="isModerator"
    :status="status"
    @restart="onRestart"
  />
</template>

<script setup lang="ts">
  /// <reference types="youtube" />
  import { VideoShareStatus, type VideoShareStatusV } from '~/globals/meet';
  type Props = {
    videoId: string;
  };

  const props = defineProps<Props>();
  const emit = defineEmits(['error']);

  const meetStore = useMeetStore();
  let player: YT.Player | undefined;
  const ready = ref(false);
  const ended = ref(false);
  const rootEl = ref();

  const trigger = useScriptTriggerElement({ trigger: 'immediate', el: rootEl });

  const script = useScriptYouTubePlayer({
    scriptOptions: {
      trigger
    }
  });

  const { status } = script;
  const placeholder = computed(() => `https://i.ytimg.com/vi_webp/${props.videoId}/sddefault.webp`);

  const controlVideoPlayback = (status: VideoShareStatusV) => {
    if (ended.value) {
      ended.value = false;
      player?.seekTo(0, true);
    }
    if (status === VideoShareStatus.Play) player?.playVideo();
    if (status === VideoShareStatus.Paused) player?.pauseVideo();
  };

  const onStatStatus = (status: VideoShareStatusV) => {
    if (status === VideoShareStatus.Ended) {
      ended.value = true;
    }
    if (useIncludes([VideoShareStatus.Play, VideoShareStatus.Paused], status)) {
      controlVideoPlayback(status);
    }
  };

  const onSetMuted = (muted: boolean) => {
    if (muted) {
      player?.mute();
      return;
    }
    player?.unMute();
  };

  const onSetVolume = (volume: number) => {
    player?.setVolume(volume);
  };

  const onSetSeek = (time: number) => {
    player?.seekTo(time, true);
  };

  const getStatus = () => {
    if (!player) {
      return VideoShareStatus.Stop;
    }
    const playerState = player.getPlayerState();
    switch (playerState) {
      case YT?.PlayerState.PLAYING: {
        return VideoShareStatus.Play;
      }
      case YT?.PlayerState.PAUSED: {
        return VideoShareStatus.Paused;
      }
      case YT?.PlayerState.ENDED: {
        ended.value = true;
        return VideoShareStatus.Ended;
      }
      default: {
        return false;
      }
    }
  };

  const isMuted = () => player?.isMuted();
  const getTime = () => player?.getCurrentTime();
  const getVolume = () => player?.getVolume();

  const fireUpdateSharedVideoEvent = (opts: Partial<Omit<MeetVideoShare, 'from'>> = {}) => {
    if (!isModerator.value) return;
    const status = getStatus();
    if (!status) {
      return;
    }

    meetStore.updatedShareVideo({
      status,
      time: getTime(),
      volume: getVolume(),
      muted: isMuted(),
      ...opts
    });
  };
  const throttledFireUpdateSharedVideoEvent = useThrottle(fireUpdateSharedVideoEvent, 5000);
  const debouncedFireUpdateSharedVideoEvent = useDebounce(fireUpdateSharedVideoEvent, 200);

  const onReady = () => {
    ready.value = true;
    if (isModerator.value) {
      controlVideoPlayback(VideoShareStatus.Play);
      // @ts-expect-error
      player?.addEventListener('onVideoProgress', () => {
        throttledFireUpdateSharedVideoEvent();
      });
      // @ts-expect-error
      player?.addEventListener('onVolumeChange', () => {
        debouncedFireUpdateSharedVideoEvent();
      });
      return;
    }
    readySync();
  };

  const onRestart = () => {
    controlVideoPlayback(VideoShareStatus.Play);
  };

  const initPlayer = async (instance: any) => {
    const YouTube = instance.YT instanceof Promise ? await instance.YT : instance.YT;

    await new Promise<void>(resolve => {
      if (typeof instance.YT.Player === 'undefined') {
        YouTube.ready(resolve);
      } else {
        resolve();
      }
    });
    player = new instance.YT.Player(elVideo.value, {
      videoId: props.videoId,
      playerVars: {
        //autoplay: 0,
        fs: 0,
        rel: 0,
        modestbranding: 1,
        cc_load_policy: 1,
        playsinline: 1,
        controls: +isModerator.value
      },
      width: width.value,
      height: height.value,
      events: {
        onReady,
        onError,
        onStateChange: () => fireUpdateSharedVideoEvent()
      }
    });
  };

  const playerDestroy = () => {
    player?.destroy();
    player = undefined;
  };

  const { width, height, elVideo, isModerator, onError, readySync } = useJitsiVideoEmbed({
    emit,
    rootEl,
    type: 'Youtube',
    onSetMuted,
    onStatStatus,
    onSetVolume,
    onSetSeek,
    playerDestroy,
    initPlayer,
    script
  });

  watch(
    () => props.videoId,
    v => {
      if (v) {
        player?.loadVideoById(v);
      }
    }
  );

  watch(status, status => {
    if (status === 'error') {
      onError('Load error');
    }
  });
</script>
