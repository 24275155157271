<template>
  <ui-container
    fluid
    no-gutters-x
    spacing="none"
    class="bg-card rounded-xl p-5 flex lg:flex-row flex-wrap h-full gap-4"
  >
    <div class="lg:w-auto w-full">
      <ui-button
        icon-left="chevron_left"
        variant="secondary"
        :size="isLg ? 'lg' : 'lg-icon'"
      >
        <span class="lg:hidden inline-flex">
          {{ $t('Back') }}
        </span>
      </ui-button>
    </div>
    <common-jitsi-main-track class="flex-1 lg:max-h-auto max-h-[calc(100%-64px)]">
      <div class="absolute bottom-5 z-1 flex flex-col gap-4">
        <div
          v-if="meetStore.pendingModeration"
          class="flex flex-wrap items-center gap-2 justify-center text-center px-4 py-2 bg-card rounded-lg"
        >
          <ui-typography
            variant="h3"
            text="Asking to join meeting..."
          />
          <ui-spinner />
        </div>
        <common-jitsi-track-nav />
      </div>
    </common-jitsi-main-track>
  </ui-container>
</template>

<script setup lang="ts">
  const meetStore = useMeetStore();
  const isLg = useIsLessThan('lg');
</script>
