<template>
  <div class="min-h-dvh flex flex-col h-full w-full">
    <nuxt-layout :name="layout">
      <div class="flex-1 flex">
        <ui-loader
          fixed
          :error="error"
          :loading="loading || meetStore.isGlobalLoading"
        >
          <client-only>
            <template v-if="!loading && !error">
              <common-jitsi-connected-room v-if="meetStore.isConnectedMeet" />
              <common-jitsi-buffer-view v-else />
            </template>
          </client-only>
        </ui-loader>
      </div>
    </nuxt-layout>
    <audio
      v-for="item in audioList"
      :id="item.id"
      :key="item.id"
      :src="item.src"
    />
  </div>
</template>

<script setup lang="ts">
  definePageMeta({
    layout: false
  });
  const audioList = jitsiNotificationsAudio;
  const { error, loading } = useLoadJitsiScript();
  const meetStore = useMeetStore();
  const layout = computed(() => (meetStore.isConnectedMeet ? 'meet-room' : 'default'));

  onBeforeUnmount(() => {
    meetStore.reset();
  });
</script>
