<template>
  <div
    class="grid"
    :class="{
      'py-2.5 grid-cols-1': meetStore.isListViewMember,
      'p-2.5 grid-cols-2 gap-3': !meetStore.isListViewMember
    }"
  >
    <common-jitsi-member-list-item-lobby
      v-for="(user, participantId) in meetStore.usersLobby"
      :key="participantId"
      :is-list="meetStore.isListViewMember"
      :participant-id="participantId"
      :user="user"
    />
    <template v-for="({ user, tracks }, participantId) in remoteTracksGroup">
      <common-jitsi-member-list-item
        v-for="item in tracks"
        :key="participantId + item.track.getId()"
        :is-list="meetStore.isListViewMember"
        :participant-id="participantId"
        :video-track="item"
        :user="user"
        @select="meetStore.setActiveTrack({ participantId, trackId: item.track.getId() || '' })"
      >
        <template #action>
          <common-jitsi-actions-member-nav
            v-if="meetStore.isModerator && !item.track.isLocal()"
            :is-list="meetStore.isListViewMember"
            :user="user"
            :source="item"
            :participant-id="participantId"
          />
        </template>
      </common-jitsi-member-list-item>
    </template>
  </div>
</template>

<script setup lang="ts">
  const meetStore = useMeetStore();

  const remoteTracksGroup = computed(() => {
    return useTransform(
      meetStore.remoteTracks,
      (acc, val, key) => {
        acc[key] = {
          user: val.user,
          tracks: useFilter(val.tracks?.video, item => !item.stopped)
        };
      },
      {} as Record<
        string,
        {
          user: UserMeet;
          tracks: InnerTrackType[];
        }
      >
    );
  });
</script>
