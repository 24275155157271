<template>
  <common-jitsi-embeded-basic-embed
    ref="rootEl"
    :ready="ready"
    :placeholder="placeholder"
    :ended="ended"
    :is-moderator="isModerator"
    :status="status"
    @restart="onRestart"
  />
</template>

<script setup lang="ts">
  /// <reference types="vimeo__player" />
  import { VideoShareStatus, type VideoShareStatusV } from '~/globals/meet';

  type Props = {
    videoId: string;
  };
  const props = defineProps<Props>();

  const emit = defineEmits(['error']);
  const meetStore = useMeetStore();

  let player: Vimeo | undefined;

  const ready = ref(false);
  const ended = ref(false);
  const rootEl = ref();

  const trigger = useScriptTriggerElement({ trigger: 'immediate', el: rootEl });

  const script = useScriptVimeoPlayer({
    scriptOptions: {
      trigger
    }
  });

  const { status } = script;

  const { data: payload } = useAsyncData(
    `vimeo-embed:${props.videoId}`,
    // TODO ideally we cache this
    () => $fetch(`https://vimeo.com/api/v2/video/${props.videoId}.json`).then(res => (res as any)[0]),
    {
      watch: [() => props.videoId]
    }
  );

  const placeholder = computed(() => {
    return payload.value?.thumbnail_large;
  });

  const getStatus = async () => {
    if (!player) {
      return VideoShareStatus.Stop;
    }
    if (await player.getPaused()) {
      return VideoShareStatus.Paused;
    }
    if (await player.getEnded()) {
      return VideoShareStatus.Ended;
    }
    return VideoShareStatus.Play;
  };
  //
  const isMuted = async () => {
    const muted = await player?.getMuted();
    return !!muted;
  };
  const getTime = async () => await player?.getCurrentTime();
  const getVolume = async () => (await player?.getVolume()) || 0.6;
  const onSetSeek = async (time: number) => {
    await player?.setCurrentTime(time);
  };

  const onSetVolume = async (volume: number) => {
    await player?.setVolume(volume);
  };

  const onSetMuted = async (muted: boolean) => {
    await player?.setMuted(muted);
  };

  const fireUpdateSharedVideoEvent = async (opts: Partial<Omit<MeetVideoShare, 'from'>> = {}) => {
    if (!isModerator.value) return;
    const [status, time, volume, muted] = await Promise.all([getStatus(), getTime(), getVolume(), isMuted()]);
    meetStore.updatedShareVideo({
      status,
      time,
      volume,
      muted,
      ...opts
    });
  };
  const debounceFireUpdateSharedVideoEvent = useDebounceFn(fireUpdateSharedVideoEvent, 300);

  const controlVideoPlayback = async (status: VideoShareStatusV) => {
    if (ended.value) {
      ended.value = false;
      await player?.setCurrentTime(0);
    }
    const isPaused = await player?.getPaused();

    if (status === VideoShareStatus.Play) {
      if (isPaused) {
        await player?.play();
      }
    }
    if (status === VideoShareStatus.Paused) {
      if (!isPaused) {
        await player?.pause();
      }
    }
  };

  const onStatStatus = async (status: VideoShareStatusV) => {
    if (status === VideoShareStatus.Ended) {
      ended.value = true;
    }
    if (useIncludes([VideoShareStatus.Play, VideoShareStatus.Paused], status)) {
      await controlVideoPlayback(status);
    }
  };

  const onReady = () => {
    ready.value = true;
    if (isModerator.value) {
      controlVideoPlayback(VideoShareStatus.Play);
      return;
    }
    readySync();
  };

  const onEnded = async () => {
    ended.value = true;
    await fireUpdateSharedVideoEvent({ status: VideoShareStatus.Ended });
  };

  const onPlay = async () => {
    await fireUpdateSharedVideoEvent();
  };

  const onPause = async () => {
    await fireUpdateSharedVideoEvent();
  };

  const onVolumechange = async () => {
    debounceFireUpdateSharedVideoEvent();
  };

  const onSeeked = async () => {
    await fireUpdateSharedVideoEvent();
  };

  const onRestart = () => {
    controlVideoPlayback(VideoShareStatus.Play);
  };

  watch(
    () => props.videoId,
    v => {
      if (v) {
        player?.loadVideo(Number(v));
      }
    }
  );
  watch(status, status => {
    if (status === 'error') {
      onError('Load error');
    }
  });

  const initPlayer = async (instance: any) => {
    const Vimeo = instance.Vimeo;
    if (!Vimeo) {
      return;
    }
    const vimeoOptions: any = {
      id: props.videoId,
      controls: !!+isModerator.value,
      dnt: true,
      title: false,
      byline: true,
      portrait: true,
      loop: false
    };

    vimeoOptions.width = width.value;
    vimeoOptions.height = height.value;
    player = new Vimeo.Player(elVideo.value, vimeoOptions);

    if (player) {
      player.on('error', onError);
      player.on('loaded', onReady);
      player.on('play', onPlay);
      player.on('pause', onPause);
      player.on('volumechange', onVolumechange);
      player.on('ended', onEnded);
      player.on('seeked', onSeeked);
    }
  };

  const playerDestroy = () => {
    player?.unload();
  };

  const { width, height, elVideo, isModerator, onError, readySync } = useJitsiVideoEmbed({
    emit,
    rootEl,
    type: 'Vimeo',
    onSetMuted,
    onStatStatus,
    onSetVolume,
    onSetSeek,
    playerDestroy,
    initPlayer,
    script
  });
</script>
