<template>
  <div class="bg-only-black relative">
    <video
      ref="elVideo"
      class="aspect-video h-full w-full max-w-full"
      :src="videoUrl"
      :controls="true"
      v-bind="videoAttrs"
      @error="onError"
      @ended="onEnded"
    />
    <common-jitsi-embeded-ended-video
      v-if="ended"
      :is-moderator="isModerator"
      @restart="onRestart"
    />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    videoUrl: string;
  };

  defineProps<Props>();
  const emit = defineEmits(['error']);

  const ThrottleTime = 5000;
  const meetStore = useMeetStore();
  const elVideo = ref<HTMLVideoElement>();
  const ended = ref(false);

  const { onError, onVideoSync, isModerator } = useBasicJitsiVideoEmbed({
    emit,
    onStatStatus: status => {
      const video = elVideo.value;
      console.log(status);
      if (video) {
        if (ended.value) {
          ended.value = false;
        }
        switch (status) {
          case VideoShareStatus.Play: {
            video.play();
            return;
          }
          case VideoShareStatus.Paused: {
            video.pause();
            return;
          }
          case VideoShareStatus.Ended: {
            video.pause();
            ended.value = true;
          }
        }
      }
    },
    onSetSeek: time => {
      const video = elVideo.value;
      if (video) {
        video.currentTime = time;
      }
    },
    onSetMuted: muted => {
      const video = elVideo.value;
      if (video) {
        video.muted = muted;
      }
    },
    onSetVolume: volume => {
      const video = elVideo.value;
      if (video) {
        video.volume = volume;
      }
    }
  });

  const getStatus = () => getPlayerStatus(elVideo.value);

  const getTime = () => elVideo.value?.currentTime || 0;
  const getVolume = () => elVideo.value?.volume || 0.5;
  const isMuted = () => elVideo.value?.muted || false;

  const fireUpdateSharedVideoEvent = (opts: Partial<Omit<MeetVideoShare, 'from'>> = {}) => {
    if (!isModerator.value) return;
    const status = getStatus();
    if (status) {
      meetStore.updatedShareVideo({
        status: getStatus(),
        time: getTime(),
        volume: getVolume(),
        muted: isMuted(),
        ...opts
      });
    }
  };

  const throttledFireUpdateSharedVideoEvent = useThrottle(fireUpdateSharedVideoEvent, ThrottleTime);
  const debounceFireUpdateSharedVideoEvent = useDebounce(fireUpdateSharedVideoEvent, 200);

  const onPlay = () => {
    fireUpdateSharedVideoEvent();
  };

  const onPause = () => {
    fireUpdateSharedVideoEvent();
  };
  const onSeeked = () => {
    fireUpdateSharedVideoEvent();
  };

  const onVolumechange = () => {
    debounceFireUpdateSharedVideoEvent();
  };

  const onTimeupdate = () => {
    throttledFireUpdateSharedVideoEvent();
  };
  const onEnded = () => {
    ended.value = true;
    fireUpdateSharedVideoEvent();
  };

  const onRestart = () => {
    if (elVideo.value) {
      ended.value = false;
      elVideo.value.currentTime = 0;
      elVideo.value.play();
    }
  };

  const onLoadedmetadata = () => {
    elVideo.value?.play();
  };

  const videoAttrs = computed(() => {
    if (isModerator.value) {
      return {
        onPlay,
        onPause,
        onEnded,
        onSeeked,
        onTimeupdate,
        onVolumechange,
        onLoadedmetadata
      };
    }
    return {};
  });

  onMounted(() => {
    onVideoSync();
  });
</script>
