<template>
  <common-jitsi-actions-group-btn
    icon="mic"
    icon-off="mic_off"
    :tooltip="$t('Toggle microphone')"
    :off="!meetStore.isAccessibilityAudio"
    :disabled-nav="!meetStore.devicesList.audioInput.length"
    @toggle="meetStore.toggleLocalAudio()"
  >
    <template #nav="{ hide }">
      <common-jitsi-actions-menu-label
        icon="micro"
        text="Microphones"
      />
      <common-jitsi-actions-select-btn
        v-for="device in meetStore.devicesList.audioInput"
        :key="device.deviceId"
        :text="device.label"
        :active="meetStore.selectDevices.audioInput === device.deviceId"
        @click="
          meetStore.setAudioInputDevice(device.deviceId);
          hide();
        "
      />
      <common-jitsi-actions-menu-label :icon="meetStore.state.accessibility.audio ? 'mic_none' : 'mic_off'">
        <div
          v-if="meetStore.isAccessibilityAudio"
          class="flex-1"
        >
          <common-jitsi-audio-level :level="meetStore.state.audioLevel" />
        </div>
        <ui-typography
          v-else
          variant="small"
          class="text-gray-400"
          text="Turn on the microphone to test it."
        />
      </common-jitsi-actions-menu-label>
      <hr />
      <common-jitsi-actions-menu-label
        icon="volume_up"
        text="Speakers"
      />
      <common-jitsi-actions-select-btn
        v-for="device in meetStore.devicesList.audioOutput"
        :key="device.deviceId"
        :text="device.label"
        :active="meetStore.selectDevices.audioOutput === device.deviceId"
        @click="
          meetStore.setAudioOutputDevice(device.deviceId);
          hide();
        "
      />
    </template>
  </common-jitsi-actions-group-btn>
</template>

<script setup lang="ts">
  const meetStore = useMeetStore();
</script>
