<template>
  <ui-dropdown-menu
    :menu-items="menuItems"
    placement="top"
    :triggers="['click']"
    wrap-class="w-[300px]"
  >
    <template #btn>
      <ui-button
        size="lg-icon"
        rounded="full"
        variant="secondary"
        icon-left="more_horiz"
      />
    </template>
  </ui-dropdown-menu>
</template>

<script setup lang="ts">
  const modalMeetShareVideo = useModalMeetShareVideo({});
  const modalMeetSettings = useModalMeetSettings({});
  const meetStore = useMeetStore();
  const isMd = useIsLessThan('md');

  const menuItems = computed(() => {
    const items: NavLink[] = [
      {
        itemKey: 'shareVideo',
        linkProps: {
          variant: 'link',
          iconLeft: 'play_arrow',
          iconExtend: true,
          disabled: meetStore.isActiveShareVideo && !meetStore.videoShareState.moderator,
          text: meetStore.isActiveShareVideo ? 'Stop share video' : 'Share video',
          hideSpace: true,
          onClick: () => (meetStore.isActiveShareVideo ? meetStore.stopShareVideo() : modalMeetShareVideo.open())
        }
      },
      {
        itemKey: 'setting',
        linkProps: {
          variant: 'link',
          iconLeft: 'settings',
          iconExtend: true,
          text: 'Settings',
          hideSpace: true,
          onClick: () => modalMeetSettings.open()
        }
      }
    ];

    if (meetStore.isConnectedMeet && isMd.value) {
      return [
        {
          itemKey: 'shareScreen',
          linkProps: {
            variant: 'link',
            iconLeft: meetStore.isShareScreen ? 'pause_presentation' : 'present_to_all',
            iconExtend: true,
            text: meetStore.isShareScreen ? 'Stop sharing' : 'Screen Sharing',
            hideSpace: true,
            onClick: () => meetStore.toggleScreenShare()
          }
        },
        {
          itemKey: 'openChat',
          linkProps: {
            variant: 'link',
            iconLeft: 'chat_bubble_outline',
            active: meetStore.isShowSidebar === 'chat',
            iconExtend: true,
            text: 'Chat',
            hideSpace: true,
            onClick: () => meetStore.onToggleSidebar('chat')
          }
        },
        {
          itemKey: 'handUp',
          linkProps: {
            variant: 'link',
            iconLeft: 'pan_tool',
            active: meetStore.isActiveHand,
            iconExtend: true,
            text: 'Hand up',
            hideSpace: true,
            onClick: () => meetStore.toggleHand()
          }
        },
        {
          itemKey: 'openMemberSidebar',
          linkProps: {
            variant: 'link',
            iconLeft: 'people',
            active: meetStore.isShowSidebar === 'member',
            iconExtend: true,
            text: 'Show users',
            hideSpace: true,
            onClick: () => meetStore.onToggleSidebar('member')
          }
        },
        {
          itemKey: 'toggleFullScreen',
          linkProps: {
            variant: 'link',
            iconLeft: meetStore.isFullScreen ? 'fullscreen_exit' : 'fullscreen',
            iconExtend: true,
            text: 'Toggle fullscreen',
            hideSpace: true,
            onClick: () => meetStore.toggleFullScreen()
          }
        },
        ...items
      ];
    }

    return items;
  });
</script>
