<template>
  <audio
    ref="trackRef"
    autoplay
  />
</template>

<script setup lang="ts">
  import type JitsiRemoteTrack from 'modules/RTC/JitsiRemoteTrack';

  type Props = {
    track: JitsiRemoteTrack;
  };
  const props = defineProps<Props>();

  const trackRef = ref();

  onMounted(() => {
    props.track.attach(trackRef.value);
  });

  onBeforeUnmount(() => {
    props.track.detach(trackRef.value);
  });
</script>
