import type { VideoShareStatusV } from '~/globals/meet';

type BasicVideoEmbedOpts = {
  emit: (name: 'error' | any, ...args: any[]) => undefined;
  type: string;
  onStatStatus: (status: VideoShareStatusV) => void;
  onSetSeek: (time: number) => void;
  onSetMuted: (muted: boolean) => void;
  onSetVolume: (val: number) => void;
};

type VideoEmbedOpts = {
  script: any;
  rootEl: Ref<any>;
  playerDestroy: () => void;
  initPlayer: (instance: any) => void;
  fireUpdateSharedVideoEvent: (data?: Partial<Omit<MeetVideoShare, 'from'>>) => void;
} & Partial<BasicVideoEmbedOpts>;

const INTERVAL_UPDATE_TIME = 5000;

const defaultBasicVideoEmbedOpts: BasicVideoEmbedOpts = {
  emit: () => undefined,
  type: 'Video',
  onStatStatus: () => {},
  onSetSeek: () => {},
  onSetMuted: () => {},
  onSetVolume: () => {}
};

const useBasicJitsiVideoEmbed = (opts: Partial<BasicVideoEmbedOpts> = {}) => {
  const { type, emit, onStatStatus, onSetSeek, onSetMuted, onSetVolume } = {
    ...defaultBasicVideoEmbedOpts,
    ...opts
  };
  const meetStore = useMeetStore();
  const isModerator = computed(() => meetStore.videoShareState.moderator);

  const onError = (e: any) => {
    console.error(`${type} render error:`, e);
    emit('error', e);
  };

  const onVideoSync = () => {
    if (!isModerator.value) {
      watch(() => meetStore.videoShareState.status, onStatStatus, { immediate: true });
      watch(
        () => meetStore.videoShareState.time,
        val => onSetSeek(val || 0)
      );
      watch(
        () => meetStore.videoShareState.muted,
        val => onSetMuted(!!val)
      );
      watch(
        () => meetStore.videoShareState.volume,
        val => onSetVolume(val || 0)
      );
    }
  };

  const readySync = () => {
    onSetSeek(meetStore.videoShareState.time || 0);
    onSetVolume(meetStore.videoShareState.volume || 0);
    onSetMuted(!!meetStore.videoShareState.muted);
    onStatStatus(meetStore.videoShareState.status);
  };

  return {
    isModerator,
    onError,
    onVideoSync,
    readySync
  };
};

const useJitsiVideoEmbed = (opts: VideoEmbedOpts) => {
  let intervalUpdateId: number | undefined;

  const { script, rootEl, initPlayer, playerDestroy, fireUpdateSharedVideoEvent, ...basicJitsiVideoEmbedOpts } = opts;
  const elVideo = computed(() => rootEl.value?.elVideo);

  const width = computed(() => {
    return elVideo.value?.parentNode?.offsetWidth || 640;
  });

  const height = computed(() => {
    return elVideo.value?.parentNode?.offsetHeight || 480;
  });

  const clearIntervalUpdate = () => {
    clearInterval(intervalUpdateId);
  };

  const onIntervalUpdate = () => {
    clearIntervalUpdate();
    // @ts-expect-error
    intervalUpdateId = setInterval(() => {
      fireUpdateSharedVideoEvent();
    }, INTERVAL_UPDATE_TIME);
  };

  const { onVideoSync, onError, readySync, isModerator } = useBasicJitsiVideoEmbed(basicJitsiVideoEmbedOpts);

  onMounted(() => {
    script.onError(onError);
    script.onLoaded(initPlayer);
    onVideoSync();
  });

  onBeforeUnmount(() => {
    clearIntervalUpdate();
    playerDestroy();
  });

  return {
    isModerator,
    elVideo,
    width,
    height,
    readySync,
    onError,
    clearIntervalUpdate,
    onIntervalUpdate
  };
};

export { useBasicJitsiVideoEmbed, useJitsiVideoEmbed };
