<template>
  <div
    class="flex gap-4 lg:max-w-72 max-w-36 w-auto bg-card items-center fixed z-10 top-5 left-0 right-0 mx-auto rounded py-2 px-3"
  >
    <transition-fade>
      <common-jitsi-hand-icon
        v-if="meetStore.state.handsList.length"
        :count="meetStore.state.handsList.length"
      />
    </transition-fade>
    <ui-typography class="text-primary truncate flex-1 lg:block hidden">
      {{ roomStore.room?.name }}
    </ui-typography>
    <span class="text-body-text shrink-0 flex items-center mx-auto">
      <span class="min-w-12">{{ timeView }}</span>
      <ui-icon
        name="schedule"
        class="lg:ml-4 ml-2"
      />
    </span>
  </div>
</template>

<script setup lang="ts">
  const roomStore = useRoomStore();
  const meetStore = useMeetStore();

  const dayjs = useDayjs();
  const timeView = ref('00:00');

  useIntervalFn(
    () => {
      const conferenceTimestamp = Number(meetStore.conferenceTimestamp);

      if (!conferenceTimestamp) {
        timeView.value = '00:00';
        return;
      }

      const diffInSeconds = Math.floor(dayjs().diff(dayjs(conferenceTimestamp)) / 1000);

      const hours = Math.floor(diffInSeconds / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);
      const seconds = diffInSeconds % 60;

      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      timeView.value =
        hours > 0
          ? `${String(hours).padStart(2, '0')}:${formattedMinutes}:${formattedSeconds}`
          : `${formattedMinutes}:${formattedSeconds}`;
    },
    1000,
    {
      immediate: true
    }
  );
</script>
