<template>
  <div class="flex-1 group">
    <common-jitsi-conference-time
      :class="[
        groupVisibleIsHover,
        {
          '-translate-y-full': hoverSupported
        }
      ]"
      class="group-hover:translate-y-0"
    />
    <common-jitsi-main-track
      class="flex-1"
      full
    >
      <common-jitsi-track-nav
        :class="[
          groupVisibleIsHover,
          {
            'translate-y-full': hoverSupported
          }
        ]"
        class="absolute bottom-5 z-20 group-hover:translate-y-0"
      />
    </common-jitsi-main-track>

    <transition-expand
      axis="x"
      @after-enter="onAfterEnter"
    >
      <div
        v-show="meetStore.isShowSidebar"
        class="fixed right-0 md:w-96 w-80 h-screen top-0 bottom-0 bg-card z-30 flex flex-col"
      >
        <common-jitsi-side-panel-header />
        <div class="max-h-[calc(100%-68px)]">
          <lazy-common-room-chat-list
            v-show="meetStore.isShowSidebar === 'chat'"
            only-chat
          />
          <common-jitsi-member-list v-if="meetStore.isShowSidebar === 'member'" />
        </div>
      </div>
    </transition-expand>

    <div class="hidden">
      <audio-track
        v-for="item in audioTracks"
        :key="item.id"
        :track="item.track"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import AudioTrack from '~/components/common/jitsi/AudioTrack.vue';
  const meetStore = useMeetStore();
  const chatStore = useChatStore();
  const hoverSupported = computed(() => isHoverSupported());
  useEventListener(document, 'fullscreenchange', () => {
    meetStore.setFullScreen(isDocumentFullScreen());
  });

  const groupVisibleIsHover = computed(() => [
    {
      'invisible opacity-0': hoverSupported.value
    },
    'group-hover:opacity-100 group-hover:visible default-transition transform'
  ]);

  const audioTracks = computed(() => {
    return useTransform(
      meetStore.remoteTracks,
      (acc, val) => {
        const audioTrack = val.tracks.audio?.[0];
        if (audioTrack && !audioTrack.track.isLocal()) {
          acc.push(audioTrack);
        }

        return acc;
      },
      [] as InnerTrackType[]
    );
  });

  const onAfterEnter = () => {
    if (meetStore.isShowSidebar === 'chat') {
      chatStore.onInitScroll();
    }
  };
</script>
