<template>
  <common-jitsi-actions-group-btn
    icon="videocam"
    icon-off="videocam_off"
    :tooltip="$t('Toggle video')"
    :off="!meetStore.isAccessibilityVideo"
    :disabled-nav="!meetStore.devicesList.video.length"
    @toggle="meetStore.toggleLocalVideo()"
  >
    <template #nav="{ hide }">
      <common-jitsi-actions-select-btn
        v-for="device in meetStore.devicesList.video"
        :key="device.deviceId"
        :text="device.label"
        :active="meetStore.selectDevices.video === device.deviceId"
        @click="
          meetStore.setVideoDevice(device.deviceId);
          hide();
        "
      />
    </template>
  </common-jitsi-actions-group-btn>
</template>

<script setup lang="ts">
  const meetStore = useMeetStore();
</script>
