<template>
  <div class="flex gap-2.5 p-2.5">
    <ui-button
      icon-left="close"
      rounded="full"
      size="lg-icon"
      icon-class-left="text-error group-hover:text-white"
      variant="gray"
      @click="meetStore.onToggleSidebar(null)"
    />
    <common-room-chat-header-info-room
      :title-class="meetStore.isShowSidebar === 'member' ? 'md:max-w-32 max-w-24' : 'md:max-w-48 max-w-44'"
      small
    />
    <template v-if="meetStore.isShowSidebar === 'member'">
      <ui-button
        icon-left="people"
        rounded="full"
        size="lg-icon"
        variant="gray"
      />
      <ui-button
        :icon-left="meetStore.isListViewMember ? 'list' : 'grid_view'"
        rounded="full"
        size="lg-icon"
        variant="gray"
        @click="meetStore.onToggleListViewMember()"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
  const meetStore = useMeetStore();
</script>
