<template>
  <ui-dropdown-menu
    :menu-items="menuItems"
    placement="auto-end"
    :class="{ 'absolute top-2 right-2': !isList }"
    class="default-transition invisible opacity-0 ml-auto group-hover/member:visible group-hover/member:opacity-100 shrink-0 z-1"
  >
    <template #btn>
      <ui-button
        :size="isList ? 'md-icon' : 'sm-icon'"
        :rounded="isList ? 'sm' : 'full'"
        variant="secondary"
        icon-left="more_horiz"
      />
    </template>
  </ui-dropdown-menu>
</template>

<script setup lang="ts">
  type Props = {
    isList: boolean;
    source: InnerTrackType;
    user: UserMeet;
    participantId: string;
  };
  const props = defineProps<Props>();
  const meetStore = useMeetStore();
  const confirmModal = useConfirmModal({});
  const { t } = useI18n();
  const confirmGrantModerator = () => {
    confirmModal.open({
      title: t('JITSI.MODALS.GRANT_RIGHTS.TITLE'),
      body: t('JITSI.MODALS.GRANT_RIGHTS.BODY'),
      rejectText: 'JITSI.MODALS.GRANT_RIGHTS.CANCEL',
      confirmText: 'JITSI.MODALS.GRANT_RIGHTS.SUCCESS',
      onSuccess: () => {
        meetStore.grantModerator(props.participantId);
      }
    });
  };

  const confirmKick = () => {
    confirmModal.open({
      title: t('JITSI.MODALS.ADMIN_KICKED.TITLE'),
      body: t('JITSI.MODALS.ADMIN_KICKED.BODY'),
      rejectText: 'JITSI.MODALS.ADMIN_KICKED.CANCEL',
      confirmText: 'JITSI.MODALS.ADMIN_KICKED.SUCCESS',
      onSuccess: () => {
        meetStore.kickParticipant(props.participantId);
      }
    });
  };

  const menuItems = computed(() => {
    const isMutedAudio = meetStore.remoteTracks[props.participantId]?.tracks?.audio?.[0]?.muted;
    const isMutedVideo = props.source.muted;
    const items: NavLink[] = [
      {
        linkProps: {
          variant: 'link',
          iconLeft: isMutedAudio ? 'mic' : 'mic_off',
          iconExtend: true,
          text: isMutedAudio ? 'Ask to unmute' : 'Mute audio',
          hideSpace: true,
          onClick: () => {
            if (isMutedAudio) {
              meetStore.askToUnmute(props.participantId);
              return;
            }
            meetStore.muteParticipant(props.participantId, TrackTypeMeet.audio);
          }
        }
      }
    ];

    if (!isMutedVideo) {
      items.push({
        linkProps: {
          variant: 'link',
          iconLeft: 'videocam_off',
          iconExtend: true,
          text: 'Mute video',
          hideSpace: true,
          onClick: () => meetStore.muteParticipant(props.participantId, TrackTypeMeet.video)
        }
      });
    }

    if (!props.user.moderator) {
      items.push({
        linkProps: {
          variant: 'link',
          iconLeft: 'add_moderator',
          iconExtend: true,
          text: 'Grant Moderator Rights',
          hideSpace: true,
          onClick: confirmGrantModerator
        }
      });
    }

    items.push({
      linkProps: {
        variant: 'link',
        iconLeft: 'not_interested',
        iconExtend: true,
        text: 'Kick out',
        hideSpace: true,
        onClick: confirmKick
      }
    });

    return items;
  });
</script>
