const calculateVideoDimensions = (el?: HTMLElement, videoAspectRatio = 16 / 9) => {
  if (!el) {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  // Отримуємо розміри батьківського елемента
  const width = el.clientWidth;
  const height = el.clientHeight;

  let videoWidth, videoHeight;

  const parentAspectRatio = width / height;

  if (parentAspectRatio > videoAspectRatio) {
    videoHeight = height;
    videoWidth = videoHeight * videoAspectRatio;
  } else {
    videoWidth = width;
    videoHeight = videoWidth / videoAspectRatio;
  }

  return {
    width: videoWidth,
    height: videoHeight
  };
};

export { calculateVideoDimensions };
