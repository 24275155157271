<template>
  <div class="w-full h-full absolute inset-0 flex">
    <div class="bg-[#000] absolute inset-0 z-1" />
    <div class="h-[calc(100%-80px)] flex-1 relative z-2 flex w-full">
      <component
        :is="providerAttrs.tag"
        v-bind="providerAttrs.props"
        class="!w-full !h-full !aspect-video"
        @error="onError"
      />
      <div
        v-if="!isModerator"
        class="absolute z-1 inset-0 w-full h-full opacity-0 select-none"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import getVideoID from 'get-video-id';
  import {
    CommonJitsiEmbededYoutube,
    CommonJitsiEmbededVimeo,
    CommonJitsiEmbededTwitch,
    CommonJitsiEmbededBasicVideo
  } from '#components';
  type Props = {
    videoUrl: string;
  };

  const props = defineProps<Props>();

  const meetStore = useMeetStore();
  const isModerator = computed(() => meetStore.videoShareState.moderator);

  const providerAttrs = computed(() => {
    const { provider, isValid } = validateDomains(props.videoUrl, ['youtube', 'vimeo', 'twitch']);

    if (isValid) {
      switch (provider) {
        case 'youtube': {
          return {
            tag: CommonJitsiEmbededYoutube,
            props: {
              videoId: getVideoID(props.videoUrl)?.id
            }
          };
        }
        case 'vimeo': {
          return {
            tag: CommonJitsiEmbededVimeo,
            props: {
              videoId: getVideoID(props.videoUrl)?.id
            }
          };
        }
        case 'twitch': {
          return {
            tag: CommonJitsiEmbededTwitch,
            props: {
              videoUrl: props.videoUrl
            }
          };
        }
      }
    }
    return {
      tag: CommonJitsiEmbededBasicVideo,
      props: {
        videoUrl: props.videoUrl
      }
    };
  });

  const onError = () => {
    if (isModerator.value) {
      const toast = useToast();
      toast.error(MessagesError.meetVideoError);
      meetStore.stopShareVideo();
    }
  };
</script>
