<template>
  <common-jitsi-member-list-item
    :user="user"
    :is-list="isList"
    :participant-id="participantId"
  >
    <template #action>
      <div
        class="flex gap-2 items-center justify-center z-1 shrink-0"
        :class="{ 'absolute inset-0': !isList }"
      >
        <ui-button
          icon-left="check"
          size="md-icon"
          @click="meetStore.onLobbyApproveAccess(participantId)"
        />
        <ui-button
          icon-left="close"
          size="md-icon"
          variant="error"
          @click="meetStore.onLobbyDenyAccess(participantId)"
        />
      </div>
    </template>
    <template #default="{ userName }">
      <ui-typography>
        <span class="text-primary">
          {{ userName }}
        </span>
        {{ $t('wants to join') }}
      </ui-typography>
    </template>
  </common-jitsi-member-list-item>
</template>

<script setup lang="ts">
  type Props = { isList?: boolean; participantId: string; user: UserMeet };
  defineProps<Props>();

  const meetStore = useMeetStore();
</script>
