<template>
  <div class="bg-only-black relative">
    <div
      ref="elVideo"
      class="video-embed-player w-full h-full"
    />
    <img
      v-if="!ready && placeholder"
      :src="placeholder"
      alt="placeholder"
      class="w-full h-full object-cover cursor-pointer z-2 absolute inset-0"
    />
    <common-jitsi-embeded-ended-video
      v-if="ended"
      :is-moderator="isModerator"
      @restart="$emit('restart')"
    />

    <div
      v-if="status === 'loading'"
      class="absolute flex items-center justify-center inset-0 z-5"
    >
      <ui-spinner />
    </div>
    <common-jitsi-embeded-awaiting-load v-if="status === 'awaitingLoad'" />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    ready: boolean;
    placeholder?: string;
    ended: boolean;
    isModerator: boolean;
    status: string;
  };
  defineProps<Props>();
  defineEmits(['restart']);

  const elVideo = ref();

  defineExpose({
    elVideo
  });
</script>

<style lang="scss">
  .video-embed-player iframe {
    aspect-ratio: 16/9;
    height: 100%;
    max-width: 100% !important;
    width: 100%;
  }
</style>
