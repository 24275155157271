<template>
  <div
    class="flex gap-2.5 group/member"
    :class="{ 'items-center border-t last:border-b border-primary p-2.5': isList, 'flex-col p-2': !isList }"
  >
    <div
      class="relative group"
      @click="$emit('select')"
    >
      <transition-fade>
        <common-jitsi-hand-icon
          v-if="!isList && hasHand"
          class="z-10 absolute right-3 top-3"
        />
      </transition-fade>
      <ui-avatar
        :user-name="userName"
        :src="user.avatar"
        :rounded="isList ? 'full' : 'md'"
        :size="isList ? 'sm' : 'none'"
        :class="{ 'aspect-square w-full text-3xl': !isList }"
      />
      <common-jitsi-video-track
        v-if="videoTrack && !isList"
        v-show="!videoTrack.muted"
        :key="videoTrack.id"
        auto-mirror
        class="rounded"
        :track="videoTrack.track"
        force-update-mount
      />
      <slot
        v-if="!isList"
        name="action"
      />
    </div>
    <div>
      <slot :user-name="userName">
        <ui-typography class="text-primary break-all">
          {{ userName }}
        </ui-typography>
      </slot>
    </div>
    <transition-fade>
      <common-jitsi-hand-icon
        v-if="isList && hasHand"
        class="ml-auto"
      />
    </transition-fade>
    <slot
      v-if="isList"
      name="action"
    />
  </div>
</template>

<script setup lang="ts">
  type Props = { isList?: boolean; participantId: string; user: UserMeet; videoTrack?: InnerTrackType };
  const props = defineProps<Props>();
  defineEmits(['select']);

  const meetStore = useMeetStore();
  const userName = computed(() => {
    let name = props.user.name || 'Anonymous';
    if (props.videoTrack && props.videoTrack.track.getVideoType() === TrackTypeMeet.desktop) {
      name += ' (sharing)';
    }
    return name;
  });
  const hasHand = computed(() => meetStore.state.handsList.includes(props.participantId));
</script>
