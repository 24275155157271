<template>
  <div class="bg-card rounded-xl flex gap-2.5 py-2 px-2.5 justify-center">
    <common-jitsi-actions-sound />
    <common-jitsi-actions-video />
    <template v-if="meetStore.isConnectedMeet && !isMd">
      <ui-button
        v-tooltip="$t(meetStore.isShareScreen ? 'Stop sharing' : 'Screen Sharing')"
        size="lg-icon"
        rounded="full"
        variant="secondary"
        :icon-left="meetStore.isShareScreen ? 'pause_presentation' : 'present_to_all'"
        @click="meetStore.toggleScreenShare()"
      />
      <ui-button
        size="lg-icon"
        rounded="full"
        variant="secondary"
        icon-left="chat_bubble_outline"
        :active="meetStore.isShowSidebar === 'chat'"
        @click="meetStore.onToggleSidebar('chat')"
      />
    </template>
    <common-jitsi-actions-additional-nav v-if="meetStore.isConnectedMeet" />
    <template v-if="meetStore.isConnectedMeet && !isMd">
      <ui-button
        size="lg-icon"
        rounded="full"
        variant="secondary"
        :active="meetStore.isActiveHand"
        icon-left="pan_tool"
        @click="meetStore.toggleHand"
      />
      <ui-button
        size="lg-icon"
        rounded="full"
        variant="secondary"
        meet-store
        icon-left="people"
        :active="meetStore.isShowSidebar === 'member'"
        @click="meetStore.onToggleSidebar('member')"
      />
      <ui-button
        v-tooltip="$t('Toggle fullscreen')"
        size="lg-icon"
        rounded="full"
        variant="secondary"
        :icon-left="meetStore.isFullScreen ? 'fullscreen_exit' : 'fullscreen'"
        @click="meetStore.toggleFullScreen()"
      />
    </template>
    <template v-if="!meetStore.pendingModeration">
      <template v-if="meetStore.isConnectedMeet">
        <common-jitsi-actions-leave-moderator-btn v-if="meetStore.isModerator" />
        <common-jitsi-actions-leave-meet-btn
          v-else
          @click="meetStore.onLeaveRoom"
        />
      </template>
      <ui-button
        v-else
        icon-left="play_arrow"
        icon-class-left="text-lg"
        class="min-w-0 px-4"
        text="Start"
        :loading="meetStore.isLoadConnect"
        @click="meetStore.onConnectionRoom"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
  const meetStore = useMeetStore();
  const isMd = useIsLessThan('md');
</script>
