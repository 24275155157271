<template>
  <div class="bg-only-black z-2 absolute inset-0 flex items-center justify-center">
    <ui-button
      v-if="isModerator"
      variant="custom"
      size="xl"
      class="text-white"
      icon-left="replay"
      @click="onRestart"
    />
    <ui-typography
      v-else
      variant="h3"
      class="text-white"
      text="Video Ended"
    />
  </div>
</template>

<script setup lang="ts">
  type Props = {
    isModerator: boolean;
  };
  defineProps<Props>();
  const emit = defineEmits(['restart']);

  const onRestart = () => {
    emit('restart');
  };
</script>
