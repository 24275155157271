<template>
  <common-jitsi-embeded-basic-embed
    ref="rootEl"
    :ready="ready"
    :placeholder="placeholder"
    :ended="ended"
    :is-moderator="isModerator"
    :status="status"
    @restart="onRestart"
  />
</template>

<script setup lang="ts">
  import { VideoShareStatus, type VideoShareStatusV } from '~/globals/meet';

  type Props = {
    videoUrl: string;
  };

  const props = defineProps<Props>();
  const emit = defineEmits(['error']);
  const meetStore = useMeetStore();

  let player: any | undefined;

  const ready = ref(false);
  const ended = ref(false);
  const rootEl = ref();
  const requestURL = useRequestURL();
  const trigger = useScriptTriggerElement({ trigger: 'immediate', el: rootEl });
  const script = useScript(
    {
      src: 'https://player.twitch.tv/js/embed/v1.js',
      referrerpolicy: false,
      crossorigin: false
    },
    {
      trigger,
      use() {
        // @ts-expect-error
        return { Twitch: window.Twitch };
      }
    }
  );

  const { status } = script;

  const twitchOptions = computed(() => getTwitchId(props.videoUrl));

  const placeholder = computed(() => {
    if (twitchOptions.value) {
      if (twitchOptions.value.type === 'video') {
        return `/assets/images/placeholder-video.svg`;
      }
      return `https://static-cdn.jtvnw.net/previews-ttv/live_user_${twitchOptions.value.id}-1920x1080.jpg`;
    }
    return undefined;
  });

  const getOptions = () => {
    if (!twitchOptions.value) {
      onError(new Error('Twitch id is not defined'));
      return null;
    }
    const { type, id } = twitchOptions.value;
    return {
      width: width.value,
      height: height.value,
      [type]: id,
      parent: [requestURL.hostname]
    };
  };

  const getStatus = () => {
    if (!player || player?.getEnded()) {
      return VideoShareStatus.Ended;
    }
    if (player.isPaused()) {
      return VideoShareStatus.Paused;
    }
    return VideoShareStatus.Play;
  };

  const getTime = () => player?.getCurrentTime() || 0;
  const getVolume = () => player?.getVolume();
  const isMuted = () => player?.getMuted();
  const playerDestroy = () => {
    clearIntervalUpdate();
    player?.destroy();
    player = undefined;
  };

  const fireUpdateSharedVideoEvent = (opts: Partial<Omit<MeetVideoShare, 'from'>> = {}) => {
    if (!isModerator.value) return;
    meetStore.updatedShareVideo({
      status: getStatus(),
      time: getTime(),
      volume: getVolume(),
      muted: isMuted(),
      ...opts
    });
  };

  const controlVideoPlayback = (status: VideoShareStatusV) => {
    if (ended.value) {
      onRestart();
    }
    if (status === VideoShareStatus.Play) player?.play();
    if (status === VideoShareStatus.Paused) player?.pause();
  };

  const onReady = () => {
    ready.value = true;
    if (isModerator.value) {
      controlVideoPlayback(VideoShareStatus.Play);
      onIntervalUpdate();
      return;
    }
    readySync();
  };

  const onStatStatus = (status: VideoShareStatusV) => {
    if (status === VideoShareStatus.Ended) {
      onEnded();
    }
    if (useIncludes([VideoShareStatus.Play, VideoShareStatus.Paused], status)) {
      controlVideoPlayback(status);
    }
  };

  const onSetSeek = (time: number) => {
    player?.seek(time);
  };

  const onSetMuted = (val: boolean) => {
    player?.setMuted(val);
  };

  const onSetVolume = (val: number) => {
    player?.setVolume(val);
  };

  const onPause = () => {
    fireUpdateSharedVideoEvent({
      status: VideoShareStatus.Paused
    });
  };

  const onPlay = () => {
    fireUpdateSharedVideoEvent({
      status: VideoShareStatus.Play
    });
  };

  const onSeek = ({ position }: { position: number }) => {
    onStatStatus(VideoShareStatus.Paused);
    fireUpdateSharedVideoEvent({
      time: position,
      status: VideoShareStatus.Play
    });
    onStatStatus(VideoShareStatus.Play);
  };

  const initPlayer = (instance: any) => {
    const Twitch = instance?.Twitch;
    if (!Twitch) {
      return;
    }
    const options = getOptions();
    if (options) {
      player = new Twitch.Player(elVideo.value, options);
      onSetSeek(0);
      player.addEventListener(Twitch.Player.READY, onReady);
      player.addEventListener(Twitch.Player.ERROR, onError);
      if (isModerator.value) {
        player.addEventListener(Twitch.Player.PAUSE, onPause);
        player.addEventListener(Twitch.Player.PLAY, onPlay);
        player.addEventListener(Twitch.Player.SEEK, onSeek);
        player.addEventListener(Twitch.Player.ENDED, onEnded);
      }
    }
  };

  const onRestart = () => {
    initPlayer(script.instance);
    ended.value = false;
  };

  const onEnded = () => {
    playerDestroy();
    ended.value = true;
    fireUpdateSharedVideoEvent({
      status: VideoShareStatus.Ended
    });
  };

  watch(twitchOptions, opts => {
    if (opts && player) {
      switch (opts.type) {
        case 'video': {
          player.setVideo(opts.id);
          return;
        }
        case 'channel': {
          player.setChannel(opts.id);
        }
      }
    }
  });

  const { width, height, elVideo, isModerator, onError, clearIntervalUpdate, onIntervalUpdate, readySync } =
    useJitsiVideoEmbed({
      emit,
      rootEl,
      type: 'Twitch',
      onSetMuted,
      onStatStatus,
      onSetSeek,
      onSetVolume,
      playerDestroy,
      initPlayer,
      fireUpdateSharedVideoEvent,
      script
    });
</script>
